// Fonts
@import url("https://fonts.googleapis.com/css?family=Nunito");
@import url("https://fonts.googleapis.com/css2?family=Baloo+Da+2:wght@400;500;600;700;800&display=swap");

// Variables
@import "variables";

// Bootstrap
@import "~bootstrap/scss/bootstrap";

//BACKGROUND

.bg_pattern {
    // padding: 20px;
    margin: 20px;
    font-family: $font-family-FIRST;
    background: linear-gradient(
        121deg,
        rgba(4, 102, 172, 1) 0%,
        rgba(4, 156, 220, 1) 21%,
        rgba(4, 212, 251, 1) 44%,
        rgba(4, 180, 235, 1) 61%,
        rgba(27, 196, 236, 1) 82%,
        rgba(4, 172, 236, 1) 97%,
        rgba(4, 128, 198, 1) 100%
    );
    background: url(/img/bg.svg) no-repeat fixed center;
    
    color: rgb(255, 255, 255);
    animation: fadeIn 1s;

}

.header {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 3rem;
    // margin-top: 3.6rem;
}
.header__title {
    font-family: $font-family-FIRST;
    font-weight: $font-title-weight;
    color: $font-color-title;
    letter-spacing: $font-title-spacing;
    line-height: 0.6;
    text-transform: uppercase;
    font-size: 2.2rem;
}
.header__description {
    @extend .header__title;
    font-weight: $font-title-sub-weight;
    text-transform: inherit;
    letter-spacing: inherit;
    font-size: 1rem;
}
.button_lamp {
    height: 80%;
    opacity: 0.19;
    position: absolute;
}

.bubble__link {
    color: $font-color-title;
    font-size: 18px;
    text-decoration: none;
    border-radius: 50%;
    text-transform: uppercase;

    font-family: $font-family-FIRST;
    font-weight: $font-title-weight;

    /* for centering background image */

    /* for centering text */
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    line-height: 0.9;
    transition: transform 0.2s;
    &:hover {
        transform: scale(1.1);
    }
    
}

.bubble__why {
    border: 3px solid $the-why-stroke;
    background-color: $the-why-color;

    /* bubble size */
    height: 80px;
    width: 80px;

    /* for absolute positioning */
    position: absolute;
    top: 20%;
    left: 10%;

    //ANIMATION FLOATING
    animation: 4s Infinite floating;
    animation-delay: 0.3s;

}

.bubble__inspired {
    border: 3px solid $get-inspired-stroke;
    background-color: $get-inspired-color;

    /* bubble size */
    height: 100px;
    width: 100px;

    /* for absolute positioning */
    position: absolute;
    top: 20%;
    right: 10%;

    //ANIMATION FLOATING
    animation: 3s Infinite floating;
    animation-delay: 1.2s;

    font-size: 1.1rem;
   
}
.bubble__genie__advice {
    display: none;
    border: 3px solid $share-your-wish-stroke;
    border-radius: 43% 57% 100% 0% / 76% 57% 43% 24%;
    background-color: $wishes-button-color;

    /* bubble size */
    height: 180px;
    width: 180px;

    /* for absolute positioning */
    position: absolute;
    top: 35%;
    left: 26%;
    z-index: 1;

    transition: transform 0.4s;
    &:hover {
        transform: scale(1.1);
        background-color: $share-your-wish-color;
        color: $font-color-title;
    }

    font-size: 1rem;
    // animation: 3s Infinite dream_breath;

    animation: fadeIn 2s;
    // animation-delay: 0.5s;
    // transition: transform 0.4s;

    @keyframes fadeIn {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }
}

.bubble__wish {
    border: 3px solid $share-your-wish-stroke;
    background-color: $share-your-wish-color;

    /* bubble size */
    height: 180px;
    width: 180px;

    /* for absolute positioning */
    position: absolute;
    top: 40%;
    left: 26%;
    z-index: 1;
   
    font-size: 2.3rem;

    animation: 3s Infinite dream_breath;
    
}
.bubble__dreams {
    border: 3px solid $people-dream-stroke;
    background-color: $people-dream-color;

    /* bubble size */
    height: 120px;
    width: 120px;

    //ANIMATION FLOATING
    animation: 3s Infinite floating;
    animation-delay: 1s;

    /* for absolute positioning */
    position: absolute;
    bottom: 18%;
    right: 10%;
    
    animation-name: dream_breath;
    animation-duration: 4s;

    @keyframes dream_breath {
        // from {background-color: red;}
        0% {
            transform: scale(1);
        }
        25% {
            transform: scale(1.05);
        }
        50% {
            transform: scale(1.08);
        }
        100% {
            transform: scale(1);
        }
    }
}

///////////////////////////////////////////////////
.navbar {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    // justify-content: center;
    //NEW STYLE FOR THE BUBBLES TEXT SIZE
    align-items: flex-start;
    justify-content: space-evenly;
}
.nav_bubble__why {
    border: 3px solid $the-why-stroke;
    background-color: $the-why-color;
    margin: 0.2rem;
    /* bubble size */
    height: $nav_bubble_size;
    width: $nav_bubble_size;
    

    &:hover {
        animation-name: test;
        animation-duration: 4s;

        @keyframes test {
            // from {background-color: red;}
            0% {
                background-color: $the-why-color;
            }
            25% {
                background-color: $share-your-wish-color;
            }
            50% {
                background-color: $get-inspired-stroke;
            }
            100% {
                background-color: $the-why-color;
            }
        }
    }
}


.nav_bubble__inspired {
    @extend .nav_bubble__why;
    border: 3px solid $get-inspired-stroke;
    background-color: $get-inspired-color;

    &:hover {
        animation-name: test;
        animation-duration: 4s;

        @keyframes test {
            // from {background-color: red;}
            0% {
                background-color: $the-why-color;
            }
            25% {
                background-color: $share-your-wish-color;
            }
            50% {
                background-color: $get-inspired-stroke;
            }
            100% {
                background-color: $get-inspired-color;
            }
        }
    }
}
.nav_bubble__wish {
    @extend .nav_bubble__why;
    border: 3px solid $share-your-wish-stroke;
    background-color: $share-your-wish-color;

    &:hover {
        animation-name: test;
        animation-duration: 4s;

        @keyframes test {
            // from {background-color: red;}
            0% {
                background-color: $the-why-color;
            }
            25% {
                background-color: $share-your-wish-color;
            }
            50% {
                background-color: $get-inspired-stroke;
            }
            100% {
                background-color: $share-your-wish-color;
            }
        }
    }
}
.nav_bubble__dreams {
    @extend .nav_bubble__why;
    border: 3px solid $people-dream-stroke;
    background-color: $people-dream-color;

    &:hover {
        animation-name: test;
        animation-duration: 4s;

        @keyframes test {
            // from {background-color: red;}
            0% {
                background-color: $the-why-color;
            }
            25% {
                background-color: $share-your-wish-color;
            }
            50% {
                background-color: $get-inspired-stroke;
            }
            100% {
                background-color: $people-dream-color;
            }
        }
    }
}
.nav_bubble__home {
    @extend .nav_bubble__why;
    border: 3px solid $get-inspired-stroke;
    background-color: $home-button-color;

    &:hover {
        animation-name: test;
        animation-duration: 4s;

        @keyframes test {
            // from {background-color: red;}
            0% {
                background-color: $the-why-color;
            }
            25% {
                background-color: $share-your-wish-color;
            }
            50% {
                background-color: $get-inspired-stroke;
            }
            100% {
                background-color: $home-button-color;
            }
        }
    }
}



.flag_container{
    
    text-align: center;
    filter: drop-shadow(0 0 0.1rem);
    
}
//animacion para JS de la bandera
#dynamicText {
    transition: opacity 0.4s;
}


.nav_button_flag{
    transition: transform 0.4s;
    height: 3rem;
    width: auto;
    opacity: 1;
}
.nav_button_flag:hover{
    transform: scale(1.2);
}

.nav_button_lamp {
    height: 65%;
    opacity: 1;
    // position: absolute;
}
.nav_button_genie {
    height: 80%;
    opacity: 1;
    // position: absolute;
}
.nav_bubble {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}
.nav_bubble_text {
    color: $font-color-title;
    //new size, before 8px
    font-size: 13px;
    margin-top: 7px;

    text-decoration: none;
    border-radius: 50%;
    text-transform: uppercase;
    text-align: center;

    font-family: $font-family-FIRST;
    font-weight: $font-title-weight;
}

.bubble__text {
    text-decoration: none;
}



///////////////////////////////////////////////////////
//GENIO ANIMACION
.genie_pic {
    max-width: 80vw;
    animation: 1.6s 1 forwards ease-in lightSpeedLeft,
        1.5s Infinite linear floating;
    animation-delay: 0s, 1.6s;
}

.genius {
    position: fixed;
    width: 90%;
    bottom: -6rem;
    left: -4.5rem;
    transition: transform 0.4s;

    &:hover {
        transform: scale(1.2);
    }
}

@keyframes slideLeft {
    0% {
        opacity: 0;
        transform: translateX(-30px);
    }
    100% {
        opacity: 1;
        transform: translateX(0);
    }
}
@keyframes floating {
    0% {
        opacity: 1;
        transform: translateY(0px);
    }
    50% {
        transform: translateY(-10px);
    }
    100% {
        opacity: 1;
        transform: translateY(0px);
    }
}
@keyframes lightSpeedLeft {
    from {
        transform: translate3d(-50%, 0, 0) skewX(20deg);
        opacity: 0;
    }
    60% {
        transform: skewX(-10deg);
        opacity: 1;
    }
    80% {
        transform: skewX(2deg);
    }
    to {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
}
.animator-hidden {
    display: none;
}

/////// FOOTER

.footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 1.7rem;

    // margin-top: 157px;
}
.footer__title {
    font-family: $font-family-FIRST;
    font-weight: $font-title-weight;
    color: $font-color-title;
    letter-spacing: $font-title-spacing;
    line-height: 0.6;
    text-transform: uppercase;
    font-size: 2.2rem;
}
.footer__description {
    @extend .footer__title;
    font-weight: $font-title-sub-weight;
    text-transform: inherit;
    letter-spacing: inherit;
    font-size: 1rem;
}

.inspired{
    text-decoration: none;
}

/////////////THE WHY
.get__inspired__text_box {
    margin-top: 1rem;
    background-color: rgba(13, 202, 250, 0.934);
   // box-shadow: 0 0 10px rgba(0, 0, 0, 0.5); /* sombra inicial */
    border-radius: 36px 22px 50px 0px;
    max-width: 50vw;
    transition: all 0.5s ease; /* transición suave */
    box-shadow: 0 0 43px rgba(10, 78, 92, 0.6); 
    border: 3px solid $the-why-stroke;
    
}
.get__inspired__click__text__box {
    background-color: #0dcaf0;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    border-radius: 86px 44px 66px 0px;
    border: 3px solid $the-why-stroke;
    padding: 1rem;
    max-width: 50vw;
    height: auto;
    text-align: center;
    box-shadow: 0 0 20px rgba(13, 202, 240, 0.6); /* sombra inicial más amplia */
    transition: all 0.5s ease; /* transición suave */

    @keyframes card_shado_move {
        50% { 
            box-shadow: 1px 1px 0 rgba(255, 255, 255, 0.1215686275), 
                        -1px 0 40px 0 #0dcaf0, 
                        40px 40px 40px 0 rgba(13, 202, 240, 0.3); /* sombra animada más amplia */
        }
    }
}

.the__why__text_box {
   
    //background-color: rgba(26, 53, 97, 0.3);
    // // height: 68vh;
    border-radius: 15px;
   

    display: flex;
    
    padding: 1rem;
    min-height: 60vh;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 1rem;
}

.get__inspired__big__text__box {
    @extend .the__why__text_box;
    //background-color: rgba(26, 53, 97, 0);
    align-items: center;
    
    z-index: 1;
    position: relative;
    
    
}

.the_why__text {
    font-size: 3.7vw;
    padding: 0.5rem;
    text-align: left;
    color: $font-color-title;
    margin: 0;
}

.the__get__inspired__text {
    padding: 1.3rem;
    @extend .the_why__text;
    text-align: center;
}
//////////// NO INFO IN THE DATABASE

.nodatabase__the__text {
    @extend .the_why__text;
    text-align: center;
}
.nodatabase__genius {
    @extend .genius;
    position: fixed;
    width: 90%;
    bottom: 3rem;
    left: 0.5rem;
}

.title {
    margin: 1rem;
}

.container__of__vh {
    min-height: 52vh;
    display: flex;
    align-content: center;
    justify-content: center;
    flex-direction: column;
    align-items: stretch;
}
.generic__title {
    font-family: $font-family-FIRST;
    font-weight: $font-title-weight;
    color: $font-color-title;
    letter-spacing: $font-title-spacing;
    line-height: 0.6;
    text-transform: uppercase;
    text-align: center;
    font-size: 2.2rem;
    animation-delay: 2s;
    animation: fadeIn 2s;
}
.generic__subtitle {
    @extend .generic__title;
    font-weight: $font-title-sub-weight;
    letter-spacing: inherit;
    font-size: 1rem;
    // margin-top: 1rem;
}
.dreams__title {
    @extend .generic__title;
    letter-spacing: 1px;
    font-size: 1.8rem;
}
/////////////////////////////////////////////////////////////////////
.filter__buttons {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    margin: 2rem;
}
.filter__buttons__dreams {
    background-color: $share-your-wish-color;
    color: $font-color-title;

    border: none;
    border-radius: 10px;
    margin-right: 7px;

    text-transform: uppercase;
    font-weight: $font-title-weight;
    &:hover {
        background-color: #000000;
        
    }
   
}
.filter__buttons__goals {
    @extend .filter__buttons__dreams;
    background-color: $goals-button-color;
}
.filter__buttons__wishes {
    @extend .filter__buttons__dreams;
    background-color: $wishes-button-color;
}
.filter__buttons__ideas {
    @extend .filter__buttons__dreams;
    background-color: $the-why-color;
}
.filter__buttons__all {
    @extend .filter__buttons__dreams;
    background-color: $people-dream-color;
}

////////////////////////////////////////////
.dreams__box__header {
    display: flex;
    flex-direction: column;
    align-content: center;
}
.dreams__box__title {
    text-transform: uppercase;
    font-weight: 650;
    font-size: 1rem;
    color: $font-color-title;

p .dreams__box__content{
    font-weight: 100;
}

}
.dreams__box__dreams {
    background-color: $share-your-wish-color;
    color: $font-color-title;
    padding: 0.7rem;
    border-radius: 20px;
    margin: 0.5rem;
    text-align: center;
    //text-transform: uppercase;
    //font-weight: $font-title-weight;

    box-shadow: $box-shadow-dreams;

    flex-direction: column;
    align-items: center;

    animation: 5s Infinite dream_messages;

    @keyframes dream_messages {
        
        0% {
            transform: scale(1);
        }
        65% {
            transform: scale(1.05);
        }
        100% {
            transform: scale(1);
        }
    }

    transition: transform 0.4s;
    &:hover {
        transform: scale(1.05);
    }
}
.dreams__box__goals {
    @extend .dreams__box__dreams;
    background-color: $goals-button-color;
    color: $font-color-title;
}
.dreams__box__wishes {
    @extend .dreams__box__dreams;
    background-color: $wishes-button-color;
    color: $font-color-title;
}
.dreams__box__ideas {
    @extend .dreams__box__dreams;
    background-color: $the-why-color;
    color: $font-color-title;
}
.img__responsive {
   
    // width: 100%;
    max-width: 300px;
    height: auto;
    //margin-top: 1rem;
    //border-radius: 91% 9% 94% 6%/9% 96% 4% 91%;
    transition: transform 0.2s;

    box-shadow: $box-shadow;

    &:hover {
        transform: scale(1.03);
    }
}

////////////////////

input[type="text"],
select {
    width: 100%;
    padding: 12px 20px;
    margin: 8px 0;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    background: rgb(255 255 255 / 80%);
}

input[type="submit"] {
    width: 100%;
    background-color: #5adbb5;
    font-size: 1.5rem;
    color: white;
    padding: 14px 20px;
    margin: 8px 0;
    border: none;
    border-radius: 4px;
    cursor: pointer;

    text-transform: uppercase;
    font-weight: $font-title-weight;
    color: $font-color-title;
}

input[type="submit"]:hover {
    background-color: $wishes-button-color;
}

.share__form {
    border-radius: 15px;
    padding: 20px;
}

.share__form__titles {
    font-weight: 650;
}

.share__form__textarea {
    width: 100%;
    height: 150px;
    padding: 12px 20px;
    box-sizing: border-box;
    border: 2px solid #ccc;
    border-radius: 4px;
    background-color: rgb(255 255 255 / 80%);
    font-size: 16px;
    resize: none;
}

// MEDIAQ

@media (min-width: 768px) {
    //TABLET
    // GET INSPIRED SECTION
    .header__title {
        font-size: 4.2rem;
    }
    .header__description {
        font-size: 2rem;
    }

    .the_why__text {
        font-size: 1.3rem;
    }
    .generic__subtitle {
        font-size: 1.8rem;
    }

    .container__of__vh {
        align-items: center;
    }

    .dreams__box__dreams {
        width: 50vw;
    }

    .img__responsive {
        max-width: 32vw;
        height: auto;
        display: block;
        margin-left: auto;
        margin-right: auto;
    }

    ///BUBBLES

    .bubble__why {
        height: 150;
        width: 150;
        font-size: 2rem;
    }

    .bubble__inspired {
        height: 135;
        width: 135;
        font-size: 1.6rem;
    }

    .bubble__dreams {
        height: 165;
        width: 165;
        font-size: 2rem;
    }

    .bubble__wish {
        height: 250;
        width: 250;
        top: 38%;
        left: 35%;
    }
    .dreams__box__dreams {
        animation: none;
    }
    .bubble__genie__advice {
        top: 40%;
    }
}

@media (min-width: 992px) {
    //DESKTOP
    //GENERAL

    .get__inspired__big__text__box {
        max-width: 43vw;

        align-items: center;
    }
    .bg_pattern {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .img__responsive {
        width: 80%;
    }

    // GET INSPIRED SECTION
    .the__why__text_box {
        max-width: 45vw;
    }

    .the_why__text {
        font-size: 1.2rem;
    }
    .generic__subtitle {
        font-size: 1.8rem;
     
    }

    // COMMENTS

    .dreams__box__dreams {
        width: 50vw;
    }
    .dreams__box__goals {
        width: 50vw;
    }
    .dreams__box__wishes {
        width: 50vw;
    }
    .dreams__box__ideas {
        width: 50vw;
    }
    
}

@media (min-width: 1200px) {
    //LARGE DESKTOP
    // GET INSPIRED SECTION
}
//SPECIAL GENIE MESSAGGE FOR MOBILES
@media (min-height: 890px) and (min-width: 414px) {
    .bubble__genie__advice {
        height: 250;
        width: 250;
        font-size: 1.3rem;
    }
}

@media (min-height: 667px) and (min-width: 375px) {
    .bubble__genie__advice {
        top: 40%;
    }
}

@media (min-height: 740px) and (min-width: 360px) {
    .bubble__genie__advice {
        top: 40%;
    }
}

//GENIUS and Bubble
@media (min-width: 496px) {
    .genius {
        position: fixed;
        width: 70%;
        bottom: -7rem;
        left: -3.5rem;
    }
    .nodatabase__genius {
        position: fixed;
        width: 80%;
        bottom: -2rem;
        left: 0.5rem;
    }
    .bubble__wish {
        left: 36%;
    }
    .bubble__genie__advice {
        top: 30%;
        height: 250;
        width: 250;
        font-size: 1.6rem;
    }
}

@media (min-width: 718px) {
    .genius {
        position: fixed;
        width: 50%;
        bottom: -6rem;
        left: -1.5rem;
    }

    .nodatabase__genius {
        position: fixed;
        width: 60%;
        bottom: -9rem;
        left: 0.5rem;
    }
}
@media (min-width: 1200px) {
    .genius {
       
        bottom: -8rem;
        left: 7.5rem;
    }

    .header {
        margin-top: 2rem;
    }

    .nodatabase__genius {
        position: fixed;
        width: 60%;
        bottom: -26rem;
        left: 9.5rem;
    }
    .bubble__wish {
        left: 40%;
    }
    .bubble__genie__advice {
        left: 38%;
        border-radius: 50%;
        height: 350;
        width: 350;
    }
}
@media (min-width: 1500px) {
    .bubble__wish {
        left: 42%;
    }
}

//////////// PRUEBA CON JAVASCRIPT

.error__form__text {
    @extend .dreams__title;
    line-height: 1;
    color: $share-your-wish-color;
    //To make a border text, the use of shadows.
    text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
}

.inactive {
    font-size: 25px;
    display: none;
}

.no_underline {
    text-decoration: none;
}

///DOWNLOAD DREAM
/// 
.fade {
    animation: fadeIn 0.5s;
}

@keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
}

.card__box__generic {
    
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    border-radius: 10px;
    border: 3px solid $the-why-stroke;

    padding: 1rem;

    max-width: 400px;
    height: auto;
    text-align: center;

    animation: card_shado_move 5s infinite;
    

    @keyframes card_shado_move{
        50% {     box-shadow: 1px 1px 0 rgba(255, 255, 255, 0.1215686275), -1px 0 28px 0 #0dcaf0, 28px 28px 28px 0 rgba(13, 202, 240, 0.0901960784);
        }
    }
    

}

.card__box__dreams {
    background-color: #FBAB7E;
    background-image: linear-gradient(62deg, #FBAB7E 0%, #F7CE68 100%);

   
    animation-delay: 0s, 0.9;

}
.card__box__goals {
    background-color: #1f87a1;
    background-image: linear-gradient(90deg, #1f87a1 0%, #1bc4ec 100%);

}
.card__box__wishes {
    background-color: #0093E9;
    background-image: linear-gradient(160deg, #0093E9 0%, #80D0C7 100%);

}
.card__box__ideas {
    background-color: #FAD961;
    background-image: linear-gradient(90deg, #FAD961 0%, #dd7973 100%);

}

.logo__dreamcloud {
    text-align: center;
}
.logo__title {
    font-size: 1rem;
    text-transform: uppercase;
    font-weight: 800;
    line-height: 0.1;
    letter-spacing: 2px;
}
.logo__subtitle {
    font-size: 0.5rem;
    font-weight: 700;
}

.card__box__dream {
    text-align: center;
    margin-top: 1rem;
    font-size: 1rem;
}
.card__box__author {
    text-transform: uppercase;
    text-align: center;
    font-size: 0.95rem;
}
.card__box__date {
    font-size: 0.9rem;
    text-align: center;
}
.card__container {
    display: flex;
    justify-content: center;
    align-items: center;
}
.card__box__type {
    background-color: $share-your-wish-color;
    color: $font-color-title;

    border: none;
    border-radius: 10px;
    //margin-right: 7px;
    width: 100%;
    margin-bottom: 1rem;
    //TRYOUT
    

    text-transform: uppercase;
    font-weight: $font-title-weight;
}
.card__box__img__responsive{
    
       
        display: block;
        margin-left: auto;
        margin-right: auto;

         // width: 100%;
    max-width: 300px;
    height: auto;
    //margin-top: 1rem;
    border-radius: 91% 9% 94% 6%/9% 96% 4% 91%;
    transition: transform 0.2s;

    box-shadow: $box-shadow;

    &:hover {
        transform: scale(1.03);
    }
}

.language-button{
    background: none;
    border: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white;
}

.language-selection{
    display: flex;
    justify-content: center;
}


.card__button__back {
    background-color: $people-dream-color;
    color: $home-button-color;
    border: none;
    border-radius: 10px;
    margin-right: 7px;
    text-transform: uppercase;
    font-weight: 800;
    text-decoration: none;
    padding: 0.5rem;
}
.soon__text {
    text-transform: uppercase;
    text-align: center;
    margin: 1rem;
    font-weight: $font-title-weight;
}

.photo_output {
    display: flex;
    justify-content: center;
    align-items: center;
    //height: 100vh; /* Opcional: ajusta según el diseño */
    
    overflow: hidden; /* Evita barras de desplazamiento */
}

.photo_output canvas {
    width: 100%; /* Asegura que el canvas no exceda el tamaño del contenedor */
    height: 100%; /* Asegura que el canvas no exceda el tamaño del contenedor */
    display: flex;
    justify-content: center;
    align-items: center;
}

